@import "@coreui/coreui-pro/scss/functions";
// Core Tiko colors
$tikoCorePurple: #841f71;
$tikoCoreGreen: #76cf3f;
$tikoCoreBlue: #32388e;
$tikoBlue: #3fb1cf;
$tikoDarkBlue: #112047;
$tikoLightGray: #c8cfd7;
$tikoDarkGray: #8190a4;
$tikoDisabledGrayBackground: #fafafa;
$tikoDisabledGrayColor: #bbbfc8;
$tikoDisabledGrayBorder: #eaedf5;
$tikoGradientPink: #fee6ee;
$tikoGradientPurple: #c2d6f6;
$tikoWarningOrange: #ff9000;
$tikoDarkBlueRGBA: rgba(17, 32, 71, 0.7);
// gap-3
$tikoGap: 1rem;
$tikoDanger: #ff006b;
$tikoYellow: #ffd000;
$btnLineHeight: 15px;
$btnPadding: 12px 20px 12px 20px;

// Variable overrides
$pagination-active-bg: $tikoCoreBlue;
$primary: $tikoCorePurple;
$primary-base: $tikoCorePurple;

// Colors
$blue: $tikoCoreBlue;
$indigo: $tikoDarkBlue;
$purple: $tikoCorePurple;
$green: $tikoCoreGreen;

// Override theme colors
$theme-colors: (
    "primary": $tikoCorePurple,
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "green": $green
);
