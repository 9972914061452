/*** IMPORTANT - Make note of the order of imports ***/

// Manage custom fonts
@import "app/lib/assets/styles/_fonts.scss";
// To override CoreUI variables
// Find the variables inside of node_modules
// And place them in the _variables file
@import "app/lib/assets/styles/_variables.scss";
// Import the framework
@import "node_modules/@coreui/coreui-pro/scss/coreui.scss";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "@ng-select/ng-select/themes/default.theme.css";

body {
  overflow: auto !important;
}

.spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  border-radius: 5px;
}

.offcanvas-end {
  width: 35%;
}

.offcanvas-body {
  min-height: 500px;
  height: 100%;
}

.filter-button {
  width: 10vw;
}

@media only screen and (max-width: 1024px) {
  .offcanvas-end {
    width: 100%;
  }

  .add-button {
    width: 100%;
  }

  .filter-button {
    width: 100%;
  }

  .toggle-children {
    top: 0px;
  }
}

.login-modal c-modal-dialog .modal-content {
  padding: 0;
}

#OffcanvasEnd {
  overflow-y: auto;
}

.card-padding {
  height: auto !important;
  display: block !important;

  &.card-padding {
    height: auto !important;
    display: block !important;
  }
}

.notification-btn {
  color: black;

  &:hover {
    color: white;
  }
}

.ml-1 {
  margin-left: ($spacer * 0.25) !important;
}

.ml-2 {
  margin-left: ($spacer * 0.5) !important;
}

.ml-3 {
  margin-left: $spacer !important;
}

.ml-4 {
  margin-left: ($spacer * 1.5) !important;
}

.ml-5 {
  margin-left: ($spacer * 3) !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-1 {
  margin-right: ($spacer * 0.25) !important;
}

.mr-2 {
  margin-right: ($spacer * 0.5) !important;
}

.mr-3 {
  margin-right: $spacer !important;
}

.mr-4 {
  margin-right: ($spacer * 1.5) !important;
}

.mr-5 {
  margin-right: ($spacer * 3) !important;
}

.mr-auto {
  margin-right: auto !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.tab-content {
  background-color: white;
  padding: 1rem;
}

.card-padding {
  padding: 0 !important;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px;
  height: auto !important;
  display: block !important;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.card-header-actions {
  display: inline-block;
  float: right;
  margin-right: -0.25rem;
}

.tikoCoreBlueCard {
  background-color: $tikoCoreBlue;
  color: white;
}

.tikoDarkBlueCard {
  background-color: $tikoDarkBlue;
  color: white;
}

.tikoCoreGreenCard {
  background-color: $tikoCoreGreen;
  color: white;
}

.tikoCorePurpleCard {
  background-color: $tikoCorePurple;
  color: white;
  border: 1.5px inset white;
}

.tikoCoreGreyCard {
  background-color: rgba(0, 0, 0, 0.2);
  color: $tikoCoreBlue;
}

#page-container {
  position: relative;
  min-height: 100vh;
  background-color: #e4e5e6;
}

#content-wrap {
  padding-bottom: 3.5rem;
}

.divider {
  height: 1px;
  background-color: rgba(154, 154, 154, 0.6);
  width: 100%;
}

.cst-avatar {
  width: 85px;
  height: 45px;
}

.tiko-core-purple-btn {
  background-color: $tikoCorePurple;
  color: #fff;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.tiko-core-purple-btn:hover {
  background-color: transparent;
  color: $tikoCorePurple;
  border: 1px solid $tikoCorePurple;
}

.tiko-core-green-btn {
  background-color: $tikoCoreGreen;
  color: #fff;
  border: 1px solid $tikoCoreGreen;
}

.tiko-core-green-btn:hover {
  background-color: transparent;
  color: $tikoCoreGreen;
  border: 1px solid $tikoCoreGreen;
}

.tiko-core-blue-btn {
  background-color: $tikoCoreBlue;
  color: #fff;
  border: 1px solid $tikoCoreBlue;
}

.tiko-core-blue-btn:hover {
  background-color: transparent;
  color: $tikoCoreBlue;
  border: 1px solid $tikoCoreBlue;
}

.border-between > [class*="col-"]:before {
  background: #e3e3e3;
  bottom: 0;
  content: " ";
  left: 0;
  position: absolute;
  width: 1px;
  top: 0;
}

.border-between > [class*="col-"]:first-child:before {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.refresh-btn {
  background: transparent;
  border: 0;
}

.offcanvas-end > div {
  overflow: auto;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #5c6873;
  background-color: white;
  border-color: #c8ced3 #c8ced3 #e4e5e6;
  border-bottom: 0;
  border-top-right-radius: 15px;
}

.cst-info-btn {
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 100%;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  font-weight: 600;
  font-size: 15px;
}

.required-label:after {
  color: red;
  content: " *";
}

.card-padding {
  padding: 0 !important;
  background-color: white;
  box-shadow: #64646f33 0 7px 29px;
  height: auto;
  border-radius: 5px;
}

.set-margin {
  margin-bottom: 5rem;
}

.flex-end {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
  background: #23244c;
  color: white;
}

.close-btn {
  z-index: 999999;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.higher-z-index {
  z-index: 1051;
}

.table-tiko-secondary-blue {
  background-color: #23244c !important;
  color: #fff !important;

  tr th {
    background-color: #23244c !important;
    color: #fff !important;
  }
}

.generate-tiko-card-container {
  position: relative;
}

.spinner-card-container {
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-btn {
  transform: rotate(0deg);
  width: auto;
  margin-top: 0 !important;
  background-color: #ffffff;
  border: 0;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
}

.thead {
  tr th {
    background-color: transparent;
    color: $tikoDarkGray !important;
    border: none;
    font-size: 12px;
    font-weight: 400;
  }
}

@keyframes shake {
  1% {
    transform: rotate(10deg);
  }

  3% {
    transform: rotate(-10deg);
  }

  5% {
    transform: rotate(10deg);
  }

  7% {
    transform: rotate(-10deg);
  }

  9% {
    transform: rotate(10deg);
  }

  11% {
    transform: rotate(-10deg);
  }

  13% {
    transform: rotate(0deg);
  }

  11% {
    transform: rotate(0deg);
  }
}

.form-check-input:checked {
  background-color: $tikoBlue;
  border-color: $tikoBlue;
}

.form-check-input {
  border: 1px solid $tikoDarkGray;
  border-radius: 2px;
}

.create__card-container,
.modal-content {
  border-radius: 20px;
  padding: 1rem;
}

.modal-backdrop,
.offcanvas-backdrop {
  background-color: $tikoDarkBlueRGBA;
  backdrop-filter: blur(5px);
}

.modal-backdrop.show,
.offcanvas-backdrop.show {
  opacity: 100%;
}

.modal-footer,
.modal-header {
  border: 0;
  justify-content: start;
}

.modal-title,
.offcanvas-title {
  font-size: 18px;
  color: $tikoDarkBlue;
}

.modal-subtitle {
  color: $tikoDarkGray;
  font-size: 12px;
}

.half-width-table {
  width: 100%;
}

.text-tiko-blue {
  color: $tikoBlue !important;
}

label {
  font-size: 12px;
  font-weight: 400;
  color: $tikoDarkBlue;
}

.tiko-missing-label {
  color: $tikoDisabledGrayColor;
  font-size: 14px;
  font-weight: 400;
}

textarea.form-control {
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  min-height: 100px;
}

.form-control {
  border: 1px solid $tikoDisabledGrayBorder;
  border-radius: 8px;
  color: $tikoDarkGray;
  height: 36px;
  padding: 0px 16px 0px 16px;

  &:disabled {
    background-color: $tikoDisabledGrayBorder;
  }
}

.cst-nav-tabs {
  gap: 1.5rem;
}

.cst-nav-tabs .nav-link {
  font-size: 12px;
  color: $tikoDarkGray;
  border: 1px solid $tikoDisabledGrayBorder;
  padding: 10px 40px 10px 40px;
}

.cst-nav-tabs .nav-link.active,
.cst-nav-tabs .show > .nav-link,
.cst-nav-tabs .nav-link:hover,
.cst-nav-tabs .nav-link:focus,
.cst-nav-tabs .nav-link:focus-visible,
.cst-nav-tabs .nav-link:disabled {
  background: #f4dee7;
  color: $tikoDarkBlue;
  border: 1px solid transparent !important;
}

.fa-chevron-left-cst {
  color: $tikoBlue;
}

.tiko-btn-padding {
  padding: $btnPadding;
  line-height: $btnLineHeight;
}

.tiko-btn-padding:hover > .danger,
.tiko-btn-padding:active > .danger,
.tiko-btn-padding:focus > .danger,
.tiko-btn-padding:target > .danger,
.tiko-btn-padding:focus-visible > .danger,
.tiko-btn-padding:focus-within > .danger {
  color: $tikoDanger;
}

.tiko-blue-btn {
  background-color: $tikoBlue;
  color: #ffffff;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &:disabled {
    background-color: $tikoDisabledGrayBackground;
    color: $tikoDisabledGrayColor;
    border: 1px solid $tikoDisabledGrayBorder;
    cursor: not-allowed;
  }

  &:not(:disabled):hover,
  &:not(:disabled):active,
  &:not(:disabled):focus,
  &:not(:disabled):target,
  &:not(:disabled):focus-visible,
  &:not(:disabled):focus-within {
    background-color: #ffffff;
    color: $tikoDarkBlue;
    border: 1px solid $tikoDisabledGrayBorder;
  }
}

.tiko-blue-btn:hover fa-icon,
.tiko-blue-btn:active fa-icon,
.tiko-blue-btn:focus fa-icon,
.tiko-blue-btn:target fa-icon,
.tiko-blue-btn:focus-visible fa-icon,
.tiko-blue-btn:focus-within fa-icon {
  color: $tikoBlue;
}

.tiko-danger-btn {
  background-color: transparent;
  color: $tikoDanger;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid $tikoDanger;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &:disabled {
    background-color: $tikoDisabledGrayBackground;
    color: $tikoDisabledGrayColor;
    border: 1px solid $tikoDisabledGrayBorder;
    cursor: not-allowed;
  }

  &:not(:disabled):hover,
  &:not(:disabled):active,
  &:not(:disabled):focus,
  &:not(:disabled):target,
  &:not(:disabled):focus-visible,
  &:not(:disabled):focus-within {
    background-color: transparent;
    color: $tikoDanger;
    border: 1px solid $tikoDanger;
  }
}

.light-gray-btn,
.light-gray-tooltip fa-icon {
  color: $tikoLightGray;
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
}

.light-gray-tooltip:hover fa-icon {
  color: $tikoBlue;
}

.light-gray-btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
}

.light-gray-btn:hover,
.light-gray-btn:active,
.light-gray-btn:focus,
.light-gray-btn:target,
.light-gray-btn:focus-visible,
.light-gray-btn:focus-within {
  color: $tikoBlue;
  background-color: transparent;
}

.light-gray-btn.active-danger:hover,
.light-gray-btn.active-danger:active,
.light-gray-btn.active-danger:focus,
.light-gray-btn.active-danger:target,
.light-gray-btn.active-danger:focus-visible,
.light-gray-btn.active-danger:focus-within {
  color: $tikoDanger;
  background-color: transparent;
}

.filter-input-container:hover > .filter-input-btn {
  background-color: $tikoBlue;
  color: #ffffff;
}

.filter-input-btn {
  position: absolute;
  right: 10px;
  background: $tikoLightGray;
  color: #ffffff;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  cursor: pointer;
}

.tiko-return-link {
  color: $tikoDarkGray;
  font-size: 12px;
  text-decoration: none;
}

.tiko-return-link:hover > fa-icon,
.tiko-return-link:active > fa-icon,
.tiko-return-link:focus > fa-icon,
.tiko-return-link:target > fa-icon,
.tiko-return-link:focus-visible > fa-icon,
.tiko-return-link:focus-within > fa-icon {
  color: $tikoBlue;
}

.tiko-filter-btn {
  background-color: $tikoYellow;
  color: $tikoDarkBlue;
  border: 1px solid transparent;
  border-radius: 62px;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;

  &:disabled {
    background-color: $tikoDisabledGrayBackground;
    color: $tikoDisabledGrayColor;
    border: 1px solid $tikoDisabledGrayBorder;
    cursor: not-allowed;
  }

  &:not(:disabled):hover,
  &:not(:disabled):active,
  &:not(:disabled):focus,
  &:not(:disabled):target,
  &:not(:disabled):focus-visible,
  &:not(:disabled):focus-within {
    background-color: #ffffff;
    color: $tikoDarkBlue;
    border: 1px solid $tikoDisabledGrayBorder;
  }
}

.tiko-gradient-btn {
  background-color: #ffffff;
  color: $tikoDarkBlue;
  border: 1px solid $tikoDisabledGrayBorder;
  gap: 10px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;
  cursor: pointer;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:disabled {
    background-color: $tikoDisabledGrayBackground;
    color: $tikoDisabledGrayColor;
    border: 1px solid $tikoDisabledGrayBorder;
    cursor: not-allowed;
  }

  &:not(:disabled):hover,
  &:not(:disabled):active,
  &:not(:disabled):focus,
  &:not(:disabled):target,
  &:not(:disabled):focus-visible,
  &:not(:disabled):focus-within {
    background: linear-gradient(84.42deg, $tikoGradientPink 0%, $tikoGradientPurple 100%);
    color: $tikoDarkBlue;
    border: 1px solid transparent;
  }
}

.tiko-gradient-active-btn {
  background: linear-gradient(84.42deg, $tikoGradientPink 0%, $tikoGradientPurple 100%);
  color: $tikoDarkBlue;
  border: 1px solid transparent;
  box-shadow: none;
}

hr {
  background-color: $tikoDisabledGrayBorder;
}

c-smart-pagination > .pagination,
c-pagination > .pagination {
  justify-content: center !important;
  gap: $tikoGap;
  width: 100%;
  overflow-x: auto;

  & > .page-item > .page-link {
    border: 0px;
    background-color: transparent;
    color: $tikoDarkBlue;
    border-radius: 100% !important;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 300;

    &:hover {
      color: $tikoBlue;
    }
  }

  & > .page-item:first-child > .page-link,
  & > .page-item:last-child > .page-link {
    color: $tikoBlue;
  }

  & > .page-item.active > .page-link {
    background-color: $tikoBlue;
    color: #ffffff;
  }
}

ng-select select-dropdown > div {
  z-index: 2;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: none !important;
  border-style: none !important;
  border-width: 0 !important;
}

.ng-select .ng-arrow-wrapper {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23636f83' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 12px 12px;
  margin-top: auto;
  font-weight: 400;
}

.ng-select-action-btn {
  cursor: pointer;
  background: none;
  border: none;
}

.ng-select .ng-has-value {
  padding: 0px 16px 0px 16px;
}

.ng-select.ng-select-disabled > .ng-select-container {
  padding: 0px 16px 0px 16px;
}

.ng-select .ng-select-container {
  border: 1px solid $tikoDisabledGrayBorder;
  border-radius: 8px;
  color: $tikoDarkBlue;
  padding: 0px 16px 0px 16px;
  overflow: auto;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-radius: 0;
}

.form-select {
  border: 1px solid $tikoDisabledGrayBorder;
  border-radius: 8px;
  color: $tikoDarkBlue;
  overflow: auto;
}

.tiko-filter-field,
.tiko-filter-field .ng-select-container,
.tiko-filter-field > fieldset > .date-picker > .date-picker-input-group {
  border-radius: 62px;
}

.tiko-filter-field > fieldset > .date-picker > .date-picker-input-group {
  padding: 0px 16px 0px 16px;
  border: 1px solid $tikoDisabledGrayBorder;
}

.tiko-filter-field > fieldset > .date-picker > .date-picker-input-group > input {
  border: 0;
}

.table-status-badge {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
}

.bg-badge-danger {
  background-color: $tikoDanger;
}

.bg-badge-success {
  background-color: $tikoCoreGreen;
}

.bg-badge-secondary {
  background-color: $tikoDarkGray;
}

.bg-badge-warning {
  background-color: $tikoWarningOrange;
}

.bg-badge-disabled {
  background-color: $tikoDisabledGrayBorder;
  color: $tikoDarkBlue;
}

.tiko-card-badge {
  width: 120px;
  height: 30px;
}

.tiko-text-btn {
  font-size: 12px;
  font-weight: 400;
  background-color: transparent;
  border: 0;
}

.tiko-text-purple {
  color: $tikoCorePurple;
}

@media only screen and (min-width: 1024px) {
  .half-width-table {
    width: 50%;
  }
}

@media only screen and (max-width: 450px) {
  .cst-nav-tabs {
    justify-content: center;
  }
}
